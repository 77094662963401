import React, { useContext, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import orderImage from "./../assets/image/svg/register-order.svg"
import { Button, Section } from "../components/Core";
import { navigate } from "gatsby"
import { Container, Col, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';


const SuccessOrderPage = () => {
    const gContext = useContext(GlobalContext);
    const order_id = gContext.goGetRegistrationData()?.orderId;
    const order_value = gContext.goGetRegistrationData()?.totalPrice;

    useEffect(() => {
        gContext.setHeaderVisible(true);
        gContext.setFooterVisible(true);

        gContext.hideFullScreenLoader();
        gContext.goResetRegistrationData()
        if (typeof window !== 'undefined') {
            localStorage.removeItem('registrationData')
            gContext.goResetRegistrationData()
        }
    }, [])

    return (
        <>
            <Helmet>
                <script src={`https://secure.adnxs.com/px?id=1716401&seg=34711278&other=${order_value}&order_id=${order_id}&t=2`} type="text/javascript"></script>
            </Helmet>
            <Section className="green-bg text-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} md={10} sm={8}>
                            <img src={orderImage} className="mt-30 mb-30" />
                            <h2>Great news! Your service re-contract has been successfully completed.</h2>
                            <Button
                                onClick={() => {
                                    navigate('/');
                                }}
                                className="mt-50 light-bg"
                            >Return to homepage</Button>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    )
}

export default SuccessOrderPage